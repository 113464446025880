export function convertFileSize(sizeInBytes: number): string {
  const KB = 1024;
  const MB = KB * KB;

  if (sizeInBytes >= MB) {
    return (sizeInBytes / MB).toFixed(2) + ' MB';
  } else {
    return (sizeInBytes / KB).toFixed(2) + ' KB';
  }
}
