import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoUnCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 6.37256H7C6.44772 6.37256 6 6.82027 6 7.37256V17.3726C6 17.9248 6.44772 18.3726 7 18.3726H17C17.5523 18.3726 18 17.9248 18 17.3726V7.37256C18 6.82027 17.5523 6.37256 17 6.37256ZM7 17.3726V7.37256H17V17.3726H7Z"
      fill={props.fill}
    />
    <mask id="mask0_36246_6909" maskUnits="userSpaceOnUse" x="6" y="6" width="12" height="13">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 6.37256H7C6.44772 6.37256 6 6.82027 6 7.37256V17.3726C6 17.9248 6.44772 18.3726 7 18.3726H17C17.5523 18.3726 18 17.9248 18 17.3726V7.37256C18 6.82027 17.5523 6.37256 17 6.37256ZM7 17.3726V7.37256H17V17.3726H7Z"
        fill={props.fill}
      />
    </mask>
  </svg>
);
const Memo = memo(SvgIcoUnCheck);
export default Memo;
