import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoClock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.3889 11.8385V8.5C12.3889 8.38981 12.3516 8.29745 12.2769 8.22292C12.2023 8.14838 12.1098 8.11111 11.9995 8.11111C11.8891 8.11111 11.7968 8.14838 11.7225 8.22292C11.6483 8.29745 11.6111 8.38981 11.6111 8.5V11.9058C11.6111 11.9852 11.6256 12.0621 11.6545 12.1366C11.6834 12.2111 11.7318 12.2822 11.7996 12.35L14.5607 15.1111C14.6335 15.1839 14.7227 15.2228 14.8284 15.2278C14.9341 15.2328 15.0283 15.1939 15.1111 15.1111C15.1939 15.0283 15.2353 14.9366 15.2353 14.8359C15.2353 14.7352 15.1939 14.6434 15.1111 14.5607L12.3889 11.8385ZM12.0026 19C11.0346 19 10.1246 18.8163 9.27241 18.449C8.42026 18.0816 7.67901 17.583 7.04865 16.9533C6.4183 16.3235 5.91927 15.5829 5.55156 14.7316C5.18385 13.8802 5 12.9706 5 12.0026C5 11.0346 5.18368 10.1246 5.55104 9.27241C5.91841 8.42026 6.41698 7.67901 7.04674 7.04865C7.67651 6.4183 8.41706 5.91927 9.26841 5.55156C10.1198 5.18385 11.0294 5 11.9974 5C12.9654 5 13.8754 5.18368 14.7276 5.55104C15.5797 5.91841 16.321 6.41697 16.9514 7.04674C17.5817 7.67651 18.0807 8.41706 18.4484 9.26841C18.8161 10.1198 19 11.0294 19 11.9974C19 12.9654 18.8163 13.8754 18.449 14.7276C18.0816 15.5797 17.583 16.321 16.9533 16.9514C16.3235 17.5817 15.5829 18.0807 14.7316 18.4484C13.8802 18.8161 12.9706 19 12.0026 19ZM12 18.2222C13.7241 18.2222 15.1921 17.6162 16.4042 16.4042C17.6162 15.1921 18.2222 13.7241 18.2222 12C18.2222 10.2759 17.6162 8.80787 16.4042 7.59583C15.1921 6.3838 13.7241 5.77778 12 5.77778C10.2759 5.77778 8.80787 6.3838 7.59583 7.59583C6.3838 8.80787 5.77778 10.2759 5.77778 12C5.77778 13.7241 6.3838 15.1921 7.59583 16.4042C8.80787 17.6162 10.2759 18.2222 12 18.2222Z"
      fill={props.fill}
    />
  </svg>
);
const Memo = memo(SvgIcoClock);
export default Memo;
