import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2273 0.375488H0.772666C0.34589 0.375488 0 0.721377 0 1.14815V13.6028C0 14.0295 0.34589 14.3755 0.772666 14.3755H7.47787V8.95394H5.65337V6.84105H7.47787V5.28287C7.47787 3.47464 8.58226 2.49001 10.1954 2.49001C10.9681 2.49001 11.6322 2.5475 11.8257 2.5732V4.46295L10.7069 4.46347C9.82965 4.46347 9.65974 4.88033 9.65974 5.49206V6.84105H11.752L11.4796 8.95394H9.65974V14.3755H13.2273C13.6541 14.3755 14 14.0295 14 13.6028V1.14815C14 0.721377 13.6541 0.375488 13.2273 0.375488Z"
      fill={props.fill}
    />
  </svg>
);
const Memo = memo(SvgIcoFacebook);
export default Memo;
