import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoX = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_32581_181627)">
      <path
        d="M16.0139 6.37549H18.1606L13.4472 11.7422L18.9539 19.0222H14.6326L11.2492 14.5982L7.37589 19.0222H5.22922L10.2226 13.2822L4.94922 6.37549H9.37789L12.4346 10.4168L16.0139 6.37549ZM15.2626 17.7622H16.4526L8.75255 7.58882H7.47389L15.2626 17.7622Z"
        fill={props.fill}
      />
    </g>
    {/* <defs>
      <clipPath id="clip0_32581_181627">
        <rect width="14" height="12.6467" fill="white" transform="translate(5 6.37549)" />
      </clipPath>
    </defs> */}
  </svg>
);
const Memo = memo(SvgIcoX);
export default Memo;
