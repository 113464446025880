import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.1464 10.0224C16.3417 9.82716 16.6583 9.82716 16.8536 10.0224C17.0488 10.2177 17.0488 10.5343 16.8536 10.7295L12.8536 14.7295C12.6583 14.9248 12.3417 14.9248 12.1464 14.7295L8.14645 10.7295C7.95118 10.5343 7.95118 10.2177 8.14645 10.0224C8.34171 9.82716 8.65829 9.82716 8.85355 10.0224L12.5 13.6689L16.1464 10.0224Z"
      fill={props.fill}
    />
    <mask id="mask0_36441_104179" maskUnits="userSpaceOnUse" x="8" y="9" width="9" height="6">
      <path
        d="M16.1464 10.0224C16.3417 9.82716 16.6583 9.82716 16.8536 10.0224C17.0488 10.2177 17.0488 10.5343 16.8536 10.7295L12.8536 14.7295C12.6583 14.9248 12.3417 14.9248 12.1464 14.7295L8.14645 10.7295C7.95118 10.5343 7.95118 10.2177 8.14645 10.0224C8.34171 9.82716 8.65829 9.82716 8.85355 10.0224L12.5 13.6689L16.1464 10.0224Z"
        fill={props.fill}
      />
    </mask>
  </svg>
);
const Memo = memo(SvgIcoArrowDown);
export default Memo;
