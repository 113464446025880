import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoPlusTopic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 6.5C12.5 6.22386 12.2761 6 12 6C11.7239 6 11.5 6.22386 11.5 6.5L11.5 11.5L6.5 11.5C6.22386 11.5 6 11.7239 6 12C6 12.2761 6.22386 12.5 6.5 12.5L11.5 12.5L11.5 17.5C11.5 17.7761 11.7239 18 12 18C12.2761 18 12.5 17.7761 12.5 17.5L12.5 12.5L17.5 12.5C17.7761 12.5 18 12.2761 18 12C18 11.7239 17.7761 11.5 17.5 11.5L12.5 11.5L12.5 6.5Z"
      fill={props.fill}
    />
    <mask id="mask0_33867_46275" maskUnits="userSpaceOnUse" x="6" y="6" width="12" height="12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 6.5C12.5 6.22386 12.2761 6 12 6C11.7239 6 11.5 6.22386 11.5 6.5L11.5 11.5L6.5 11.5C6.22386 11.5 6 11.7239 6 12C6 12.2761 6.22386 12.5 6.5 12.5L11.5 12.5L11.5 17.5C11.5 17.7761 11.7239 18 12 18C12.2761 18 12.5 17.7761 12.5 17.5L12.5 12.5L17.5 12.5C17.7761 12.5 18 12.2761 18 12C18 11.7239 17.7761 11.5 17.5 11.5L12.5 11.5L12.5 6.5Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_33867_46275)">
      <rect width="24" height="24" fill={props.fill} />
    </g>
  </svg>
);
const Memo = memo(SvgIcoPlusTopic);
export default Memo;
