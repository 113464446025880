import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoOnline = (props: SVGProps<SVGSVGElement>) => (
  <svg id="Layer_1" viewBox="0 0 24 24" width="1em" height="1em" role="img" {...props}>
    <path
      id="online"
      d="M12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM17.75,8a7.07,7.07,0,0,0-3.41-2.6A6.62,6.62,0,0,1,15.92,8Zm.58,1H16.2a12.71,12.71,0,0,1,.29,2.5H19A7,7,0,0,0,18.33,9ZM6.25,8H8.08A6.72,6.72,0,0,1,9.65,5.4,7.09,7.09,0,0,0,6.25,8ZM5.67,9A7,7,0,0,0,5,11.5H7.51A12.71,12.71,0,0,1,7.8,9Zm9.18-1a5.19,5.19,0,0,0-2.06-2.56L12.5,5.3V8Zm.32,1H12.5v2.5h3A11.59,11.59,0,0,0,15.17,9Zm-6-1H11.5V5.3l-.3.14A5.17,5.17,0,0,0,9.15,8ZM8.83,9a11.59,11.59,0,0,0-.32,2.5h3V9Zm8.73,7.25H15.83a6.44,6.44,0,0,1-1.49,2.35A7,7,0,0,0,17.56,16.25Zm.64-1A6.89,6.89,0,0,0,19,12.5H16.49a12.66,12.66,0,0,1-.35,2.75Zm-11.76,1A7,7,0,0,0,9.65,18.6a6.55,6.55,0,0,1-1.48-2.35Zm-.64-1H7.86a12.66,12.66,0,0,1-.35-2.75H5A6.89,6.89,0,0,0,5.8,15.25Zm9,1H12.5V18.7l.29-.14A5.07,5.07,0,0,0,14.75,16.25Zm.36-1a11.7,11.7,0,0,0,.38-2.75h-3v2.75Zm-5.86,1a5.11,5.11,0,0,0,1.95,2.31l.3.14V16.25Zm-.36-1H11.5V12.5h-3A11.7,11.7,0,0,0,8.89,15.25Z"
    />
  </svg>
);
const Memo = memo(SvgIcoOnline);
export default Memo;
