import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoDashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 11.3336V6.00024H11.3333V11.3336H6ZM6 18.0002V12.6669H11.3333V18.0002H6ZM12.6667 11.3336V6.00024H18V11.3336H12.6667ZM12.6667 18.0002V12.6669H18V18.0002H12.6667Z"
      fill={props.fill}
    />
  </svg>
);
const Memo = memo(SvgIcoDashboard);
export default Memo;
