import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCloseModal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6569 7.05048C17.8521 6.85522 17.8521 6.53864 17.6569 6.34338C17.4616 6.14812 17.145 6.14812 16.9497 6.34338L12 11.2931L7.05025 6.34338C6.85499 6.14812 6.53841 6.14812 6.34315 6.34338C6.14788 6.53864 6.14788 6.85522 6.34315 7.05048L11.2929 12.0002L6.34315 16.95C6.14788 17.1452 6.14788 17.4618 6.34315 17.6571C6.53841 17.8523 6.85499 17.8523 7.05025 17.6571L12 12.7073L16.9497 17.6571C17.145 17.8523 17.4616 17.8523 17.6569 17.6571C17.8521 17.4618 17.8521 17.1452 17.6569 16.95L12.7071 12.0002L17.6569 7.05048Z"
      fill="white"
    />
    <mask id="mask0_33867_46259" maskUnits="userSpaceOnUse" x="6" y="6" width="12" height="12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6569 7.05048C17.8521 6.85522 17.8521 6.53864 17.6569 6.34338C17.4616 6.14812 17.145 6.14812 16.9497 6.34338L12 11.2931L7.05025 6.34338C6.85499 6.14812 6.53841 6.14812 6.34315 6.34338C6.14788 6.53864 6.14788 6.85522 6.34315 7.05048L11.2929 12.0002L6.34315 16.95C6.14788 17.1452 6.14788 17.4618 6.34315 17.6571C6.53841 17.8523 6.85499 17.8523 7.05025 17.6571L12 12.7073L16.9497 17.6571C17.145 17.8523 17.4616 17.8523 17.6569 17.6571C17.8521 17.4618 17.8521 17.1452 17.6569 16.95L12.7071 12.0002L17.6569 7.05048Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_33867_46259)">
      <rect width="24" height="24" fill={props.fill} />
    </g>
  </svg>
);
const Memo = memo(SvgIcoCloseModal);
export default Memo;
