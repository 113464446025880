import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoDOCX = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="35"
    height="41"
    viewBox="0 0 35 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.3">
      <path
        opacity="0.3"
        d="M24.2427 8.37215C23.3134 8.37125 22.4223 8.00164 21.7651 7.34448C21.108 6.68731 20.7384 5.79625 20.7375 4.86688V3.15938e-07H5.07618C4.47531 -0.000222975 3.88027 0.117915 3.32507 0.347706C2.76987 0.577496 2.26539 0.914429 1.84043 1.33923C1.41547 1.76404 1.07836 2.2684 0.848366 2.82352C0.618371 3.37863 0.5 3.97362 0.5 4.5745V32.1507C0.5 32.7516 0.618371 33.3466 0.848366 33.9018C1.07836 34.4569 1.41547 34.9612 1.84043 35.386C2.26539 35.8109 2.76987 36.1477 3.32507 36.3775C3.88027 36.6073 4.47531 36.7255 5.07618 36.7253H23.8772C25.0905 36.7253 26.254 36.2433 27.1119 35.3854C27.9697 34.5275 28.4517 33.364 28.4517 32.1507V8.37215H24.2427Z"
        fill="#0072FF"
      />
    </g>
    <path
      d="M28.4515 8.37215H24.2425C23.3132 8.37125 22.4221 8.00164 21.765 7.34447C21.1078 6.68731 20.7382 5.79625 20.7373 4.86688V0L28.4515 8.37215Z"
      fill="#0072FF"
    />
    <path
      d="M22.7013 14.2335H5.15139C4.98278 14.2335 4.82108 14.1665 4.70185 14.0472C4.58262 13.928 4.51563 13.7663 4.51563 13.5977C4.5154 13.514 4.53169 13.4312 4.56354 13.3539C4.59539 13.2765 4.64219 13.2062 4.70124 13.147C4.7603 13.0878 4.83045 13.0408 4.9077 13.0088C4.98494 12.9767 5.06776 12.9602 5.15139 12.9602H22.7013C22.8704 12.9602 23.0325 13.0274 23.1521 13.1469C23.2716 13.2665 23.3388 13.4286 23.3388 13.5977C23.3383 13.7665 23.271 13.9282 23.1515 14.0474C23.032 14.1665 22.8701 14.2335 22.7013 14.2335Z"
      fill="#0072FF"
    />
    <path
      d="M22.7013 18.0362H5.15139C4.98278 18.0362 4.82108 17.9692 4.70185 17.85C4.58262 17.7307 4.51563 17.569 4.51563 17.4004C4.5154 17.3168 4.53169 17.2339 4.56354 17.1566C4.59539 17.0793 4.64219 17.009 4.70124 16.9497C4.7603 16.8905 4.83045 16.8436 4.9077 16.8115C4.98494 16.7794 5.06776 16.7629 5.15139 16.7629H22.7013C22.8704 16.7629 23.0325 16.8301 23.1521 16.9496C23.2716 17.0692 23.3388 17.2313 23.3388 17.4004C23.3383 17.5692 23.271 17.7309 23.1515 17.85C23.032 17.9692 22.8701 18.0362 22.7013 18.0362Z"
      fill="#0072FF"
    />
    <path
      d="M22.7013 21.8389H5.15139C5.06776 21.8389 4.98494 21.8224 4.9077 21.7904C4.83045 21.7583 4.7603 21.7113 4.70124 21.6521C4.64219 21.5929 4.59539 21.5226 4.56354 21.4453C4.53169 21.3679 4.5154 21.2851 4.51563 21.2014C4.51563 21.0328 4.58262 20.8711 4.70185 20.7519C4.82108 20.6327 4.98278 20.5657 5.15139 20.5657H22.7013C22.8701 20.5657 23.032 20.6326 23.1515 20.7518C23.271 20.871 23.3383 21.0327 23.3388 21.2014C23.3388 21.3705 23.2716 21.5327 23.1521 21.6522C23.0325 21.7718 22.8704 21.8389 22.7013 21.8389Z"
      fill="#0072FF"
    />
    <path
      d="M15.7759 25.6417H5.15139C5.06776 25.6417 4.98494 25.6252 4.9077 25.5931C4.83045 25.561 4.7603 25.514 4.70124 25.4548C4.64219 25.3956 4.59539 25.3253 4.56354 25.248C4.53169 25.1707 4.5154 25.0878 4.51563 25.0042C4.51563 24.8356 4.58262 24.6738 4.70185 24.5546C4.82108 24.4354 4.98278 24.3684 5.15139 24.3684H15.7759C15.9445 24.3684 16.1062 24.4354 16.2254 24.5546C16.3446 24.6738 16.4116 24.8356 16.4116 25.0042C16.4119 25.0878 16.3956 25.1707 16.3637 25.248C16.3319 25.3253 16.2851 25.3956 16.226 25.4548C16.167 25.514 16.0968 25.561 16.0196 25.5931C15.9423 25.6252 15.8595 25.6417 15.7759 25.6417Z"
      fill="#0072FF"
    />
    <path
      d="M31.77 29.0024H10.5142C9.00647 29.0024 7.78418 30.2247 7.78418 31.7325V38.0699C7.78418 39.5776 9.00647 40.7999 10.5142 40.7999H31.77C33.2777 40.7999 34.5 39.5776 34.5 38.0699V31.7325C34.5 30.2247 33.2777 29.0024 31.77 29.0024Z"
      fill="#0072FF"
    />
    <path d="M10.9001 36.2729V37.5088H9.62012V36.2729H10.9001Z" fill="white" />
    <path
      d="M15.82 36.2763C15.6066 36.6625 15.2852 36.978 14.8952 37.1841C14.4558 37.4088 13.9674 37.5204 13.4741 37.5088H11.543V32.3971H13.4741C13.9678 32.3851 14.4569 32.4943 14.8986 32.715C15.2874 32.9169 15.608 33.2291 15.82 33.6126C16.0397 34.0206 16.1504 34.4785 16.1412 34.9419C16.15 35.4069 16.0394 35.8663 15.82 36.2763ZM14.4906 36.0197C14.6288 35.8778 14.7359 35.7087 14.805 35.5231C14.874 35.3375 14.9036 35.1396 14.8918 34.9419C14.9037 34.7445 14.8741 34.5468 14.8051 34.3614C14.736 34.1761 14.6288 34.0073 14.4906 33.8659C14.1809 33.5943 13.7764 33.4562 13.3653 33.4817H12.7805V36.4021H13.3653C13.7761 36.4273 14.1803 36.29 14.4906 36.0197Z"
      fill="white"
    />
    <path
      d="M20.584 32.6541C20.9729 32.875 21.2944 33.1976 21.5139 33.5873C21.7439 33.9989 21.8612 34.4639 21.8538 34.9354C21.8624 35.408 21.7451 35.8745 21.5139 36.2868C21.2948 36.6783 20.9726 37.0023 20.5823 37.2235C20.1849 37.4504 19.7344 37.5677 19.2768 37.5634C18.8192 37.5677 18.3686 37.4504 17.9712 37.2235C17.5809 37.0023 17.2587 36.6783 17.0397 36.2868C16.8084 35.8745 16.6911 35.408 16.6997 34.9354C16.6909 34.4637 16.8082 33.9983 17.0397 33.5873C17.2598 33.1975 17.5818 32.8749 17.9712 32.6541C18.3686 32.4271 18.8192 32.3098 19.2768 32.3141C19.7349 32.3101 20.1859 32.4274 20.584 32.6541ZM18.3061 33.861C18.0553 34.161 17.9278 34.5449 17.9491 34.9354C17.9277 35.3242 18.0554 35.7066 18.3061 36.0046C18.4283 36.1374 18.578 36.2418 18.7448 36.3105C18.9116 36.3792 19.0914 36.4106 19.2717 36.4024C19.4513 36.4098 19.6304 36.3781 19.7965 36.3095C19.9627 36.2408 20.1119 36.1367 20.2338 36.0046C20.4858 35.7071 20.6147 35.3247 20.5942 34.9354C20.6146 34.5466 20.4871 34.1646 20.2372 33.8661C20.1151 33.7332 19.9654 33.6288 19.7986 33.5601C19.6318 33.4913 19.4519 33.46 19.2717 33.4683C19.0919 33.46 18.9125 33.4909 18.7458 33.5587C18.5791 33.6265 18.4291 33.7296 18.3061 33.861Z"
      fill="white"
    />
    <path
      d="M26.4987 32.8596C26.9298 33.2104 27.223 33.7023 27.3265 34.2485H26.0176C25.9293 34.0221 25.7746 33.8278 25.5739 33.6909C25.3624 33.5531 25.1141 33.4826 24.8617 33.4886C24.695 33.4832 24.5293 33.5163 24.3775 33.5853C24.2257 33.6543 24.0918 33.7573 23.9862 33.8864C23.7496 34.1892 23.6291 34.5666 23.6462 34.9505C23.6289 35.3329 23.7496 35.7088 23.9862 36.0096C24.0927 36.1373 24.2268 36.2391 24.3786 36.3071C24.5303 36.3752 24.6955 36.4077 24.8617 36.4022C25.1141 36.409 25.3627 36.3384 25.5739 36.2C25.7735 36.0656 25.9281 35.8743 26.0176 35.6509H27.3265C27.2213 36.1954 26.9283 36.6857 26.4987 37.0364C26.0363 37.3894 25.4651 37.5698 24.8838 37.5463C24.4257 37.556 23.9736 37.442 23.5748 37.2165C23.2016 36.9996 22.8989 36.6797 22.7028 36.2952C22.4915 35.8735 22.3859 35.4068 22.3951 34.9352C22.386 34.4637 22.4916 33.997 22.7028 33.5753C22.8989 33.1907 23.2016 32.8709 23.5748 32.6539C23.9736 32.4284 24.4257 32.3145 24.8838 32.3241C25.4681 32.3087 26.0394 32.4981 26.4987 32.8596Z"
      fill="white"
    />
    <path
      d="M31.1219 37.5089L30.0441 35.9178L29.1194 37.5089H27.7016L29.337 34.8774L27.6523 32.3972H29.1194L30.1682 33.9373L31.0709 32.3972H32.4818L30.8686 34.9709L32.5889 37.5089H31.1219Z"
      fill="white"
    />
  </svg>
);
const Memo = memo(SvgIcoDOCX);
export default Memo;
