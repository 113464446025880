import React, { useImperativeHandle, useState } from 'react';
import { createPortal } from 'react-dom';

export const globalLoadingRef = React.createRef<any>();

export const globalLoading = {
  show: () => {
    globalLoadingRef.current.show();
  },
  hide: () => {
    globalLoadingRef.current.hide();
  },
};

const GlobalLoading = React.forwardRef((__props, ref) => {
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }));

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  return visible ? (
    createPortal(
      <div className="fixed right-0 top-0 z-[9999] flex h-[100vh] w-[100vw] items-center justify-center">
        <div className="overlay__loading">
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>,
      document.body,
    )
  ) : (
    <></>
  );
});

export default GlobalLoading;
