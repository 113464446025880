import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoVideos = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 8.01025C4 6.90178 4.90154 6.00474 6.01 6.01028L18.01 6.07028C19.1107 6.07578 20 6.96959 20 8.07025V16.0002C20 17.1048 19.1046 18.0002 18 18.0002H6C4.89543 18.0002 4 17.1048 4 16.0002V8.01025ZM6 7.00023C5.44772 7.00023 5 7.44794 5 8.00023V16.0002C5 16.5525 5.44772 17.0002 6 17.0002H18C18.5523 17.0002 19 16.5525 19 16.0002V8.00023C19 7.44794 18.5523 7.00023 18 7.00023H6ZM10.7772 8.518C10.4449 8.29664 10 8.53485 10 8.93412V15.0656C10 15.465 10.4452 15.7032 10.7775 15.4815L15.3761 12.4136C15.6729 12.2156 15.6728 11.7794 15.3758 11.5816L10.7772 8.518ZM14.0287 12.0002L11 10.0285V14.0015L14.0287 12.0002Z"
      fill={props.fill}
    />
  </svg>
);
const Memo = memo(SvgIcoVideos);
export default Memo;
