import { Box } from '@mui/material';
import BackToTopButton from 'components/@custom/BackToTopButton/BackToTopButton';
import ProgressBarDownload from 'components/@custom/ProccessbarDownload/ProccessbarDownload';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { IStatusDownLoad } from 'types/types';

interface Props {
  children: ReactNode;
}

const PageContainer = ({ children }: Props) => {
  const downloading = useSelector<RootState, IStatusDownLoad[]>(
    (state) => state.progressStore.downloading,
  );

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      {children}
      <ProgressBarDownload downloading={downloading} />
      <BackToTopButton />
    </Box>
  );
};

export default PageContainer;
