import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoYoutube = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1rem" height="1rem" viewBox="0 0 24 24" role="img" {...props}>
    <g clipPath="url(#clip0_767_6581)">
      <path
        d="M17.5285 5.52077C14.6754 5.32602 8.32067 5.32681 5.47146 5.52077C2.38633 5.73135 2.02296 7.59493 2 12.5001C2.02296 17.3966 2.38317 19.2681 5.47146 19.4794C8.32146 19.6734 14.6754 19.6742 17.5285 19.4794C20.6137 19.2689 20.977 17.4053 21 12.5001C20.977 7.60364 20.6168 5.73214 17.5285 5.52077ZM9.125 15.6668V9.33343L15.4583 12.4946L9.125 15.6668Z"
        fill={props.fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_767_6581">
        <rect width={19} height={19} fill={props.fill} transform="translate(2 3)" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoYoutube);
export default Memo;
