import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

const useRouteChange = (
  callbackRouteChangeStart: () => void,
  callbackRouteChangeComplete: () => void,
) => {
  const router = useRouter();
  const previousPathname = useRef(router.pathname);

  useEffect(() => {
    const handleRouteChangeStart = (url: string) => {
      const { pathname } = new URL(url, window.location.origin);
      if (pathname !== previousPathname.current) {
        callbackRouteChangeStart();
        previousPathname.current = pathname;
      }
    };

    const handleRouteChangeComplete = (url: string) => {
      // const { pathname } = new URL(url, window.location.origin);
      // if (pathname !== previousPathname.current) {
      //   previousPathname.current = pathname;
      // }
      callbackRouteChangeComplete();
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.on('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router, callbackRouteChangeStart, callbackRouteChangeComplete]);
};

export default useRouteChange;
