import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { CacheProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import GlobalLoading, { globalLoading, globalLoadingRef } from 'components/@common/Loading';
import { PageContainer } from 'components/@screens';
import DefaultSEO from 'components/@seo/DefaultSeo';
import config from 'config';
import useRouteChange from 'hooks/useRouteChange';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { ReactElement, ReactNode } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Provider } from 'react-redux';
import { IntlProvider } from 'services/i18n';
import ThemeContext from 'services/theme-context';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { createEmotionCache } from 'utils/emotion-cache.utils';
import store from '../redux/store';
import '../styles/custom.scss';
import '../styles/globals.css';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const gtmParams = {
    id: config.GTM_ID,
    environment: {
      gtm_auth: config.GTM_AUTH,
      gtm_preview: config.GTM_ENV,
    },
  };

  const queryClient = new QueryClient();

  const clientSideEmotionCache = createEmotionCache();

  useRouteChange(
    () => {
      globalLoading.show();
    },
    () => {
      globalLoading.hide();
    },
  );

  const getLayout = Component.getLayout ?? ((page) => page);

  // Router.events.on('routeChangeStart', () => {
  //   globalLoading.show();
  // });

  // Router.events.on('routeChangeComplete', () => {
  //   globalLoading.hide();
  // });

  // return (
  return getLayout(
    <CacheProvider value={clientSideEmotionCache}>
      <ThemeContext>
        <CssBaseline>
          <IntlProvider>
            <Provider store={store}>
              <GTMProvider state={gtmParams}>
                <DefaultSEO />
                <QueryClientProvider client={queryClient}>
                  <PageContainer>
                    <Component {...pageProps} />
                    <GlobalLoading ref={globalLoadingRef} />
                  </PageContainer>
                </QueryClientProvider>
              </GTMProvider>
            </Provider>
          </IntlProvider>
        </CssBaseline>
      </ThemeContext>
    </CacheProvider>,
  );
}
