import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoUnCheckboxDate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 6H7C6.44772 6 6 6.44772 6 7V17C6 17.5523 6.44772 18 7 18H17C17.5523 18 18 17.5523 18 17V7C18 6.44772 17.5523 6 17 6ZM7 17V7H17V17H7Z"
      fill={props.fill}
    />
  </svg>
);
const Memo = memo(SvgIcoUnCheckboxDate);
export default Memo;
