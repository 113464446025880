import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg id="Layer_1" viewBox="0 0 24 24" width="1em" height="1em" role="img" {...props}>
    <defs>
      <style>{'.cls-1{fillRule:evenodd;}'}</style>
    </defs>
    <path
      className="cls-1"
      d="M12,9.94A2.08,2.08,0,1,0,14.09,12a2,2,0,0,0-.6-1.47A2.09,2.09,0,0,0,12,9.94Zm0,5a3,3,0,1,1,3-3,3,3,0,0,1-3,3Zm-.82,4.09a6.78,6.78,0,0,0,1.56,0,2.14,2.14,0,0,1,1.31-2,2.19,2.19,0,0,1,2.36.53,7.35,7.35,0,0,0,1.12-1.11A2.11,2.11,0,0,1,17,14.1,2.14,2.14,0,0,1,19,12.8h.1a6.78,6.78,0,0,0,0-1.56l-.05,0a2.11,2.11,0,0,1-1.45-3.66,7.55,7.55,0,0,0-1.09-1.1A2.18,2.18,0,0,1,14.11,7a2.12,2.12,0,0,1-1.3-2.05,6.78,6.78,0,0,0-1.56,0,2.09,2.09,0,0,1-1.3,2,2.18,2.18,0,0,1-2.36-.52A7.35,7.35,0,0,0,6.47,7.53,2.13,2.13,0,0,1,7,9.9a2.19,2.19,0,0,1-2,1.3,6.7,6.7,0,0,0,0,1.55l.06,0a2.1,2.1,0,0,1,2,1.3,2.13,2.13,0,0,1-.52,2.35,7.15,7.15,0,0,0,1.09,1.11A2.16,2.16,0,0,1,9.9,17a2.12,2.12,0,0,1,1.3,2.05ZM12,20c-.31,0-.61,0-.92-.05a.87.87,0,0,1-.76-.91,1.24,1.24,0,0,0-.75-1.21,1.33,1.33,0,0,0-1.39.32.87.87,0,0,1-1.18.1A8,8,0,0,1,5.73,17a.86.86,0,0,1,.09-1.17,1.24,1.24,0,0,0,.32-1.37A1.2,1.2,0,0,0,5,13.66H5a.93.93,0,0,1-.94-.76,7.91,7.91,0,0,1,0-1.82.86.86,0,0,1,.88-.77,1.31,1.31,0,0,0,1.24-.75,1.25,1.25,0,0,0-.32-1.38A.87.87,0,0,1,5.76,7a8.29,8.29,0,0,1,1.3-1.28.87.87,0,0,1,1.17.1,1.29,1.29,0,0,0,1.37.32,1.23,1.23,0,0,0,.76-1.19.87.87,0,0,1,.77-.89,7.74,7.74,0,0,1,1.8,0,.85.85,0,0,1,.76.9,1.24,1.24,0,0,0,.75,1.21,1.33,1.33,0,0,0,1.39-.32A.86.86,0,0,1,17,5.76,8.54,8.54,0,0,1,18.28,7a.88.88,0,0,1-.1,1.17A1.23,1.23,0,0,0,19,10.35a.92.92,0,0,1,.92.76,7.91,7.91,0,0,1,0,1.82.87.87,0,0,1-.91.77H19a1.22,1.22,0,0,0-.82,2.13.87.87,0,0,1,.1,1.18A7.44,7.44,0,0,1,17,18.29a.85.85,0,0,1-1.17-.1,1.33,1.33,0,0,0-1.38-.32,1.26,1.26,0,0,0-.76,1.19.86.86,0,0,1-.76.89A8.85,8.85,0,0,1,12,20Z"
    />
  </svg>
);
const Memo = memo(SvgIcoSettings);
export default Memo;
