import { Theme } from '@emotion/react';
import { Box, BoxProps, SxProps } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props extends BoxProps {
  gap?: number | string;
  children?: ReactNode;
  sx?: SxProps<Theme>;
  onClick?: () => void;
}

const FlexWrapper = ({ gap, children, sx, onClick, ...rest }: Props) => {
  return (
    <Box
      {...rest}
      onClick={onClick}
      sx={{ display: 'flex', alignItems: 'center', gap: gap || 0, ...sx }}
    >
      {children}
    </Box>
  );
};

export default FlexWrapper;
