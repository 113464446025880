import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoArrowDownTimePicker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.6464 9.64645C15.8417 9.45118 16.1583 9.45118 16.3536 9.64645C16.5488 9.84171 16.5488 10.1583 16.3536 10.3536L12.3536 14.3536C12.1583 14.5488 11.8417 14.5488 11.6464 14.3536L7.64645 10.3536C7.45118 10.1583 7.45118 9.84171 7.64645 9.64645C7.84171 9.45118 8.15829 9.45118 8.35355 9.64645L12 13.2929L15.6464 9.64645Z"
      fill={props.fill}
    />
    <mask id="mask0_35310_3936" maskUnits="userSpaceOnUse" x="7" y="9" width="10" height="6">
      <path
        d="M15.6464 9.64645C15.8417 9.45118 16.1583 9.45118 16.3536 9.64645C16.5488 9.84171 16.5488 10.1583 16.3536 10.3536L12.3536 14.3536C12.1583 14.5488 11.8417 14.5488 11.6464 14.3536L7.64645 10.3536C7.45118 10.1583 7.45118 9.84171 7.64645 9.64645C7.84171 9.45118 8.15829 9.45118 8.35355 9.64645L12 13.2929L15.6464 9.64645Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_35310_3936)">
      <rect width="24" height="24" fill={props.fill} />
    </g>
  </svg>
);
const Memo = memo(SvgIcoArrowDownTimePicker);
export default Memo;
