import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCheckedTopic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.9526 15.8008L18.6263 7.16782C18.8098 6.96143 19.1258 6.94284 19.3322 7.1263C19.5386 7.30976 19.5572 7.6258 19.3737 7.83219L11.3737 16.8322C11.1926 17.036 10.8816 17.0571 10.6746 16.8796L7.17461 13.8796C6.96495 13.6999 6.94067 13.3843 7.12038 13.1746C7.30009 12.9649 7.61574 12.9407 7.8254 13.1204L10.9526 15.8008Z"
      fill="white"
    />
    <mask id="mask0_33867_46270" maskUnits="userSpaceOnUse" x="7" y="7" width="13" height="10">
      <path
        d="M10.9526 15.8008L18.6263 7.16782C18.8098 6.96143 19.1258 6.94284 19.3322 7.1263C19.5386 7.30976 19.5572 7.6258 19.3737 7.83219L11.3737 16.8322C11.1926 17.036 10.8816 17.0571 10.6746 16.8796L7.17461 13.8796C6.96495 13.6999 6.94067 13.3843 7.12038 13.1746C7.30009 12.9649 7.61574 12.9407 7.8254 13.1204L10.9526 15.8008Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_33867_46270)"></g>
  </svg>
);
const Memo = memo(SvgIcoCheckedTopic);
export default Memo;
