import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoPDF = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_35516_1373)">
      <path
        d="M11.75 10.5C11.75 10.3623 11.6377 10.25 11.5 10.25C11.3623 10.25 11.25 10.3625 11.25 10.5C11.25 10.5644 11.2593 11.2284 11.5357 12.0703C11.5969 11.8 11.75 11.0687 11.75 10.5ZM14 4V8H18L14 4ZM8.5 17.2C8.5 17.5017 8.82719 17.5741 8.94937 17.3992C9.10219 17.1844 9.4 16.75 9.75312 16.1438C9.30875 16.3928 8.90156 16.6847 8.565 17.0284C8.52344 17.0719 8.5 17.1344 8.5 17.2ZM13 8V4H7.5C6.67156 4 6 4.67156 6 5.5V18.5C6 19.3281 6.67156 20 7.5 20H16.5C17.3284 20 18 19.3284 18 18.5V9H14.0281C13.4469 9 13 8.55312 13 8ZM15.125 13.75C15.8831 13.75 16.5 14.3669 16.5 15.125C16.5 15.8959 15.9081 16.5 15.1522 16.5C14.9649 16.5 14.7831 16.4702 14.6141 16.4123C13.9872 16.2012 13.4147 15.9136 12.9094 15.5564C12.1631 15.7161 11.4887 15.9164 10.8997 16.1533C10.4487 16.9755 10.0363 17.5955 9.76312 17.9786C9.50312 18.3062 9.15 18.5 8.74813 18.5C8.06 18.5 7.5 17.9406 7.5 17.2531C7.5 16.8806 7.61688 16.5712 7.84938 16.3309C8.355 15.8147 9.01719 15.3625 9.81875 14.9856C10.1859 14.2722 10.5044 13.5469 10.7666 12.825C10.2562 11.575 10.25 10.5125 10.25 10.5C10.25 9.81094 10.8109 9.25 11.5 9.25C12.1894 9.25 12.75 9.81094 12.75 10.5C12.75 11.2531 12.5242 12.2422 12.4707 12.4572C12.4392 12.5595 12.4071 12.6608 12.3744 12.761C12.6191 13.2129 12.9275 13.6113 13.2944 13.9501C14.2219 13.7938 14.8844 13.7531 14.9875 13.75H15.125ZM10.8906 15.0813C11.4525 14.8781 12.0197 14.7238 12.5444 14.6066C12.1562 14.2297 11.8516 13.8216 11.6131 13.4141C11.3844 14.0188 11.1375 14.5781 10.8906 15.0813ZM15.1531 15.5C15.3438 15.5 15.5 15.3438 15.5 15.1531C15.5 14.9187 15.3313 14.75 15.125 14.75H15C14.9893 14.751 14.4741 14.7803 13.7203 14.8984C14.0725 15.1172 14.4741 15.3106 14.9341 15.4647C15 15.4875 15.0781 15.5 15.1531 15.5Z"
        fill={props.fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_35516_1373">
        <rect width="12" height="16" fill="white" transform="translate(6 4)" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoPDF);
export default Memo;
