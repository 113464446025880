import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoInputSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 16.5C7.96243 16.5 5.5 14.0376 5.5 11C5.5 7.96243 7.96243 5.5 11 5.5C14.0376 5.5 16.5 7.96243 16.5 11C16.5 12.3387 16.0217 13.5658 15.2266 14.5195L19.9536 19.2464C20.1488 19.4417 20.1488 19.7583 19.9536 19.9536C19.7583 20.1488 19.4417 20.1488 19.2464 19.9536L14.5195 15.2266C13.5658 16.0217 12.3387 16.5 11 16.5ZM11 15.5C13.4853 15.5 15.5 13.4853 15.5 11C15.5 8.51472 13.4853 6.5 11 6.5C8.51472 6.5 6.5 8.51472 6.5 11C6.5 13.4853 8.51472 15.5 11 15.5Z"
      fill={props.fill}
    />
  </svg>
);
const Memo = memo(SvgIcoInputSearch);
export default Memo;
