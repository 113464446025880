import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgVectorLine02 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 226 33" fill="none" role="img" {...props}>
    <path
      d="M4 9C67.3705 9 130.641 5 194 5C203.976 5 213.993 2 224 2"
      stroke="black"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M2 20.0002C49.6581 20.0002 97.2459 18.0632 144.889 17.2224C161.61 16.9273 178.428 17.5843 195.111 16.2224C196.586 16.102 199.982 15.0365 199 17.0002"
      stroke="black"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M2 31C22.2963 31 42.5926 31 62.8889 31C73.5388 31 84.3617 31.5115 95 30.7778C95.4803 30.7447 96.811 30.2829 96.3333 30.2222C94.1204 29.9412 91.1192 30.1177 89 30"
      stroke="black"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
const Memo = memo(SvgVectorLine02);
export default Memo;
