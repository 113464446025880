import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoPhoneNumber = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.9307 20C17.2449 20 15.5167 19.5779 13.7462 18.7337C11.9757 17.8894 10.3308 16.7044 8.8115 15.1788C7.29233 13.6532 6.11058 12.0084 5.26625 10.2443C4.42208 8.48008 4 6.75508 4 5.06925C4 4.76375 4.1 4.50917 4.3 4.3055C4.5 4.10183 4.75 4 5.05 4H7.523C7.79483 4 8.032 4.08558 8.2345 4.25675C8.43717 4.42792 8.57442 4.6475 8.64625 4.9155L9.14225 7.3C9.18842 7.5795 9.18008 7.82375 9.11725 8.03275C9.05442 8.24175 8.9435 8.41292 8.7845 8.54625L6.5905 10.5923C7.00067 11.3371 7.45417 12.0291 7.951 12.6683C8.44783 13.3074 8.97767 13.9128 9.5405 14.4845C10.1212 15.0653 10.7462 15.6054 11.4155 16.1048C12.0847 16.6041 12.8193 17.0756 13.6193 17.5193L15.7578 15.3422C15.9206 15.1666 16.1036 15.0509 16.3068 14.9952C16.5099 14.9394 16.7346 14.9282 16.9808 14.9615L19.0845 15.3923C19.3563 15.4589 19.5769 15.5958 19.7462 15.803C19.9154 16.01 20 16.2475 20 16.5155V18.95C20 19.25 19.8982 19.5 19.6945 19.7C19.4908 19.9 19.2362 20 18.9307 20ZM6.12125 9.65375L8.0405 7.8885C8.1045 7.83717 8.14617 7.76667 8.1655 7.677C8.18467 7.58717 8.18142 7.50383 8.15575 7.427L7.7115 5.30775C7.68583 5.20508 7.641 5.12817 7.577 5.077C7.51283 5.02567 7.4295 5 7.327 5H5.275C5.198 5 5.13392 5.02567 5.08275 5.077C5.03142 5.12817 5.00575 5.19225 5.00575 5.26925C5.02492 5.95258 5.132 6.666 5.327 7.4095C5.52183 8.15317 5.78658 8.90125 6.12125 9.65375ZM14.5712 17.9885C15.2467 18.3232 15.9669 18.5706 16.7318 18.7308C17.4964 18.8911 18.1627 18.977 18.7308 18.9885C18.8077 18.9885 18.8718 18.9628 18.923 18.9115C18.9743 18.8602 19 18.7961 19 18.7193V16.7115C19 16.609 18.9743 16.5257 18.923 16.4615C18.8718 16.3975 18.7949 16.3527 18.6923 16.327L16.8422 15.948C16.7654 15.9223 16.6982 15.9192 16.6405 15.9385C16.5827 15.9577 16.5218 15.9993 16.4578 16.0635L14.5712 17.9885Z"
      fill={props.fill}
    />
  </svg>
);
const Memo = memo(SvgIcoPhoneNumber);
export default Memo;
