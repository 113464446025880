import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoDownLoadFile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9984 5C12.2746 5 12.4984 5.22386 12.4984 5.5L12.3607 14.398C12.3614 14.3988 12.3621 14.3995 12.3628 14.4003L12.4984 5.5V13.5355L15.1804 10.8536C15.3757 10.6583 15.6923 10.6583 15.8875 10.8536C16.0828 11.0488 16.0828 11.3654 15.8875 11.5607L12.3522 15.0959C12.3522 15.096 12.3521 15.0961 12.352 15.0962C12.3513 15.0969 12.3506 15.0976 12.3499 15.0983C12.3024 15.1452 12.2479 15.1807 12.1898 15.2047C12.1317 15.2288 12.0681 15.2422 12.0014 15.2426H12.0014L11.9984 15.2426L11.9955 15.2426H11.9954C11.9293 15.2422 11.8663 15.229 11.8087 15.2054M11.4984 13.5355V5.5C11.4984 5.22386 11.7223 5 11.9984 5M11.4984 13.5355L8.81645 10.8536C8.62118 10.6583 8.3046 10.6583 8.10934 10.8536C7.91408 11.0488 7.91408 11.3654 8.10934 11.5607L11.6449 15.0962C11.6928 15.1441 11.7481 15.1803 11.807 15.2047M6 13.8536C6 13.5774 5.77614 13.3536 5.5 13.3536C5.22386 13.3536 5 13.5774 5 13.8536V16.3536C5 17.4581 5.89543 18.3536 7 18.3536H17C18.1046 18.3536 19 17.4581 19 16.3536V13.8536C19 13.5774 18.7761 13.3536 18.5 13.3536C18.2239 13.3536 18 13.5774 18 13.8536V16.3536C18 16.9058 17.5523 17.3536 17 17.3536H7C6.44772 17.3536 6 16.9058 6 16.3536V13.8536Z"
      fill="black"
    />
    <mask id="mask0_35516_1381" maskUnits="userSpaceOnUse" x="5" y="5" width="14" height="14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9984 5C12.2746 5 12.4984 5.22386 12.4984 5.5V13.5355L15.1804 10.8536C15.3757 10.6583 15.6923 10.6583 15.8875 10.8536C16.0828 11.0488 16.0828 11.3654 15.8875 11.5607L12.352 15.0962C12.1567 15.2915 11.8401 15.2915 11.6449 15.0962L8.10934 11.5607C7.91408 11.3654 7.91408 11.0488 8.10934 10.8536C8.3046 10.6583 8.62118 10.6583 8.81645 10.8536L11.4984 13.5355V5.5C11.4984 5.22386 11.7223 5 11.9984 5ZM6 13.8536C6 13.5774 5.77614 13.3536 5.5 13.3536C5.22386 13.3536 5 13.5774 5 13.8536V16.3536C5 17.4581 5.89543 18.3536 7 18.3536H17C18.1046 18.3536 19 17.4581 19 16.3536V13.8536C19 13.5774 18.7761 13.3536 18.5 13.3536C18.2239 13.3536 18 13.5774 18 13.8536V16.3536C18 16.9058 17.5523 17.3536 17 17.3536H7C6.44772 17.3536 6 16.9058 6 16.3536V13.8536Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_35516_1381)">
      <rect width="24" height="24" fill={props.fill} />
    </g>
  </svg>
);
const Memo = memo(SvgIcoDownLoadFile);
export default Memo;
