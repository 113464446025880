import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoArchive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="e329f7c3-cbe5-4308-831c-8c7be3560d22"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path d="M17.06,19.88H6.94a2.83,2.83,0,0,1-2.82-2.82V10.31a.57.57,0,0,1,.57-.56.56.56,0,0,1,.56.56v6.75a1.69,1.69,0,0,0,1.69,1.69H17.06a1.69,1.69,0,0,0,1.69-1.69V10.31a.56.56,0,0,1,.56-.56.57.57,0,0,1,.57.56v6.75A2.83,2.83,0,0,1,17.06,19.88ZM21,8.06V5.81a1.69,1.69,0,0,0-1.69-1.69H4.69A1.69,1.69,0,0,0,3,5.81V8.06a.56.56,0,0,0,.56.56H20.44A.56.56,0,0,0,21,8.06ZM4.12,5.81a.57.57,0,0,1,.57-.56H19.31a.57.57,0,0,1,.57.56V7.5H4.12Zm10.69,5.63a1.69,1.69,0,0,0-1.69-1.69H10.88a1.69,1.69,0,1,0,0,3.37h2.24A1.68,1.68,0,0,0,14.81,11.44Zm-1.69-.56a.56.56,0,1,1,0,1.12H10.88a.56.56,0,1,1,0-1.12Z" />
  </svg>
);
const Memo = memo(SvgIcoArchive);
export default Memo;
