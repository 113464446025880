import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIconRing = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.42702 17.41H6.56382L6.5638 17.4102C5.82396 17.4079 5.18724 16.8899 5.03412 16.1665C4.881 15.4431 5.25444 14.7118 5.92944 14.4102V14.411L6.80522 14.0227V10.8625C6.806 9.86561 7.10132 8.89061 7.65366 8.06093C8.20594 7.23125 8.9903 6.58203 9.90906 6.19609V5.94843C9.90906 4.87031 10.7833 3.99609 11.8614 3.99609C12.9395 3.99609 13.8137 4.87031 13.8137 5.94843V6.21405C14.7356 6.62343 15.5192 7.28983 16.0699 8.13515C16.6207 8.97961 16.9153 9.96555 16.9176 10.9742V14.0218L17.7934 14.41C18.4684 14.7116 18.8418 15.4428 18.6887 16.1663C18.5356 16.8897 17.8989 17.4077 17.159 17.41H14.2958C14.1012 18.6039 13.0708 19.4804 11.8614 19.4804C10.652 19.4804 9.62155 18.6038 9.42702 17.41ZM10.2435 17.4124C10.4278 18.1561 11.095 18.6788 11.8614 18.6804H11.8615C12.6279 18.6788 13.2951 18.1562 13.4795 17.4124H10.2435ZM11.8614 4.79835C12.4927 4.79835 13.0067 5.30695 13.0138 5.93819C12.6958 5.86085 12.3716 5.81632 12.0458 5.80382C11.5966 5.78741 11.1474 5.83038 10.7091 5.93194C10.7185 5.30226 11.2317 4.796 11.8614 4.796V4.79835ZM7.37548 14.646L6.26142 15.1382L6.26149 15.1382C5.93337 15.2835 5.749 15.6366 5.81853 15.989C5.88807 16.3413 6.19275 16.5983 6.55135 16.6085H17.1514C17.5139 16.6077 17.8264 16.353 17.9014 15.9983C17.9764 15.6436 17.7928 15.285 17.4615 15.1382L16.3553 14.6483C16.2107 14.5843 16.1178 14.4405 16.1178 14.2819V10.9701C16.1178 8.64433 14.278 6.68582 12.0178 6.60613H11.8615C10.7341 6.60847 9.65362 7.05769 8.85752 7.85535C8.06064 8.65375 7.61376 9.73501 7.61376 10.8624V14.2804C7.61376 14.4382 7.52001 14.5819 7.37548 14.646Z"
      fill="white"
    />
  </svg>
);
const Memo = memo(SvgIconRing);
export default Memo;
