import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/fr';
import '@formatjs/intl-relativetimeformat/locale-data/nl';
import '@formatjs/intl-relativetimeformat/locale-data/be';

import { createIntl, createIntlCache, IntlShape } from 'react-intl';

import MESSAGES_EN from './translations/en.json';
import MESSAGES_FR from './translations/fr.json';
import MESSAGES_NL from './translations/nl.json';

const intlCache = createIntlCache();

const intl: { [key: string]: IntlShape } = {
  en: createIntl({ locale: 'en', messages: MESSAGES_EN }, intlCache),
  fr: createIntl({ locale: 'fr', messages: MESSAGES_FR as typeof MESSAGES_EN }, intlCache),
  nl: createIntl({ locale: 'nl', messages: MESSAGES_NL as typeof MESSAGES_EN }, intlCache),
};

export { intl };
