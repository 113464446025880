import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoPNG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="35"
    height="41"
    viewBox="0 0 35 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.3">
      <path
        opacity="0.3"
        d="M24.2439 8.3708C23.3145 8.3699 22.4234 8.00029 21.7662 7.3431C21.109 6.6859 20.7394 5.79481 20.7385 4.8654V0H5.07469C3.86141 0 2.69782 0.481979 1.8399 1.3399C0.981979 2.19782 0.5 3.36141 0.5 4.5747V32.1521C0.501802 33.3642 0.984571 34.5261 1.8423 35.3825C2.70003 36.239 3.86259 36.72 5.07469 36.72H23.8784C25.0908 36.72 26.2536 36.2387 27.1114 35.3819C27.9692 34.5251 28.4517 33.3628 28.4531 32.1504V8.3708H24.2439Z"
        fill="#005FAD"
      />
    </g>
    <path
      d="M28.4529 8.3708H24.2437C23.3143 8.3699 22.4232 8.00029 21.766 7.3431C21.1088 6.6859 20.7392 5.79481 20.7383 4.8654V0L28.4529 8.3708Z"
      fill="#005FAD"
    />
    <path
      d="M31.7702 29.0021H10.5134C9.00555 29.0021 7.7832 30.2244 7.7832 31.7323V38.0699C7.7832 39.5777 9.00555 40.8001 10.5134 40.8001H31.7702C33.278 40.8001 34.5004 39.5777 34.5004 38.0699V31.7323C34.5004 30.2244 33.278 29.0021 31.7702 29.0021Z"
      fill="#005FAD"
    />
    <path d="M13.9403 36.2729V37.5089H12.6602V36.2729H13.9403Z" fill="white" />
    <path
      d="M15.8206 35.7171V37.5089H14.583V32.397H16.5856C17.1942 32.397 17.6572 32.5477 17.9745 32.8492C18.1356 33.0072 18.2614 33.1974 18.3438 33.4075C18.4261 33.6176 18.463 33.8427 18.4522 34.0681C18.4576 34.3654 18.3836 34.6588 18.238 34.9181C18.0904 35.1718 17.8701 35.3755 17.6056 35.5029C17.2876 35.6546 16.9378 35.728 16.5856 35.7171H15.8206ZM17.1942 34.0681C17.1942 33.6147 16.946 33.3881 16.4496 33.3881H15.8206V34.7192H16.4496C16.946 34.7237 17.1942 34.5067 17.1942 34.0681Z"
      fill="white"
    />
    <path
      d="M23.6 37.5089H22.3641L20.2935 34.3707V37.5089H19.0576V32.397H20.2935L22.3624 35.5641V32.397H23.5983L23.6 37.5089Z"
      fill="white"
    />
    <path
      d="M28.4297 32.81C28.8422 33.1186 29.1194 33.5749 29.2032 34.0833H27.8942C27.8084 33.9012 27.6705 33.7486 27.4981 33.6447C27.3034 33.5292 27.0802 33.4703 26.8538 33.4747C26.6779 33.4674 26.5026 33.4988 26.3402 33.5666C26.1777 33.6345 26.0322 33.7371 25.9137 33.8674C25.6654 34.1658 25.539 34.5472 25.5601 34.935C25.5601 35.4212 25.6842 35.7946 25.9324 36.0553C26.0719 36.1907 26.2382 36.2954 26.4206 36.3628C26.603 36.4301 26.7975 36.4585 26.9915 36.4463C27.2689 36.4511 27.5403 36.3658 27.765 36.2032C27.9939 36.0318 28.1591 35.7889 28.2342 35.513H26.6736V34.6239H29.2695V35.87C29.169 36.174 29.0115 36.4561 28.8054 36.7013C28.5829 36.9651 28.3056 37.1774 27.9928 37.3235C27.6382 37.4872 27.2511 37.5685 26.8606 37.5615C26.3861 37.5735 25.9169 37.4597 25.5006 37.2317C25.1197 37.0181 24.8095 36.6978 24.6081 36.3103C24.3926 35.8929 24.2846 35.4284 24.2936 34.9588C24.2834 34.4863 24.3915 34.0188 24.6081 33.5988C24.8092 33.2121 25.118 32.8921 25.4972 32.6774C25.9075 32.4514 26.3703 32.3382 26.8385 32.3493C27.4054 32.3215 27.9654 32.4836 28.4297 32.81Z"
      fill="white"
    />
    <path d="M9.79941 11.3423H7.46191V13.6798H9.79941V11.3423Z" fill="#005FAD" />
    <path d="M12.1383 13.6799H9.80078V16.0174H12.1383V13.6799Z" fill="#005FAD" />
    <path d="M14.4762 11.3423H12.1387V13.6798H14.4762V11.3423Z" fill="#005FAD" />
    <path d="M16.8131 13.6799H14.4756V16.0174H16.8131V13.6799Z" fill="#005FAD" />
    <path d="M19.1539 11.3423H16.8164V13.6798H19.1539V11.3423Z" fill="#005FAD" />
    <path d="M21.4918 13.6799H19.1543V16.0174H21.4918V13.6799Z" fill="#005FAD" />
    <path d="M9.79941 16.0139H7.46191V18.3514H9.79941V16.0139Z" fill="#005FAD" />
    <path d="M12.1383 18.3516H9.80078V20.6891H12.1383V18.3516Z" fill="#005FAD" />
    <path d="M14.4762 16.0139H12.1387V18.3514H14.4762V16.0139Z" fill="#005FAD" />
    <path d="M16.8131 18.3516H14.4756V20.6891H16.8131V18.3516Z" fill="#005FAD" />
    <path d="M19.1539 16.0139H16.8164V18.3514H19.1539V16.0139Z" fill="#005FAD" />
    <path d="M21.4918 18.3516H19.1543V20.6891H21.4918V18.3516Z" fill="#005FAD" />
    <path d="M9.79941 20.7078H7.46191V23.0453H9.79941V20.7078Z" fill="#005FAD" />
    <path d="M12.1383 23.0452H9.80078V25.3827H12.1383V23.0452Z" fill="#005FAD" />
    <path d="M14.4762 20.7078H12.1387V23.0453H14.4762V20.7078Z" fill="#005FAD" />
    <path d="M16.8131 23.0452H14.4756V25.3827H16.8131V23.0452Z" fill="#005FAD" />
    <path d="M19.1539 20.7078H16.8164V23.0453H19.1539V20.7078Z" fill="#005FAD" />
    <path d="M21.4918 23.0452H19.1543V25.3827H21.4918V23.0452Z" fill="#005FAD" />
  </svg>
);
const Memo = memo(SvgIcoPNG);
export default Memo;
