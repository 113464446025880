import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoWord = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_35516_4593)">
      <path
        d="M13 8V4H7.5C6.67156 4 6 4.67156 6 5.5V18.5C6 19.3281 6.67156 20 7.5 20H16.5C17.3284 20 18 19.3284 18 18.5V9H14.0281C13.4469 9 13 8.55312 13 8ZM14.7969 11.5H15.5272C15.7683 11.5 15.9469 11.7244 15.8925 11.9594L14.5656 17.7094C14.5281 17.8781 14.375 18 14.2031 18H13.2888C13.1192 18 12.9706 17.8862 12.9266 17.7224L12 14.2844L11.0747 17.6938C11.0031 17.8875 10.8812 18 10.7125 18H9.79688C9.62225 18 9.47062 17.8794 9.43156 17.7092L8.10469 11.9592C8.05187 11.725 8.23031 11.5 8.47125 11.5H9.20156C9.37619 11.5 9.52781 11.6206 9.56687 11.7907L10.3063 15L11.175 11.7781C11.2188 11.6125 11.3688 11.5 11.5375 11.5H12.4628C12.6324 11.5 12.7809 11.6138 12.825 11.7776L13.6937 15L14.4344 11.7906C14.4719 11.6219 14.5969 11.5 14.7969 11.5ZM14 4V8H18L14 4Z"
        fill={props.fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_35516_4593">
        <rect width="12" height="16" fill="white" transform="translate(6 4)" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoWord);
export default Memo;
