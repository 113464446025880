import { createSlice } from '@reduxjs/toolkit';
import { ITopics } from 'types/types';

type initialStateType = {
  topics: ITopics[] | null;
  topicDisplayLength: number;
};

const initialState: initialStateType = {
  topics: null,
  topicDisplayLength: 12,
};

const topicSlice = createSlice({
  name: 'topics',
  initialState: initialState,
  reducers: {
    setTopics: (state, action) => {
      state.topics = action.payload;
    },
    setTopicDisplayLength: (state, action) => {
      state.topicDisplayLength = action.payload;
    },
  },
});

const { reducer, actions } = topicSlice;
export const { setTopics, setTopicDisplayLength } = actions;
export default reducer;
