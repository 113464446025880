import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoEventCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 4.5C8.22386 4.5 8 4.72386 8 5V5.5V6V7V7.5V8C8 8.27614 8.22386 8.5 8.5 8.5C8.77614 8.5 9 8.27614 9 8V7.5V7V6V5.5V5C9 4.72386 8.77614 4.5 8.5 4.5ZM14 7H10V6H14V7ZM15 7.5V8C15 8.27614 15.2239 8.5 15.5 8.5C15.7761 8.5 16 8.27614 16 8V7.5V7V6V5.5V5C16 4.72386 15.7761 4.5 15.5 4.5C15.2239 4.5 15 4.72386 15 5V5.5V6V7V7.5ZM17.9231 7H17V6H17.8667C19.0449 6 20 6.83574 20 7.86667V18.1333C20 19.1643 19.0449 20 17.8667 20H6.13333C4.95513 20 4 19.1643 4 18.1333V7.86667C4 6.83574 4.95513 6 6.13333 6H7V7H6.07692C5.48215 7 5 7.41328 5 7.92308V18.0769C5 18.5867 5.48215 19 6.07692 19H17.9231C18.5178 19 19 18.5867 19 18.0769V7.92308C19 7.41328 18.5178 7 17.9231 7ZM6.5 17V10C6.5 9.72386 6.72386 9.5 7 9.5H12H17C17.2761 9.5 17.5 9.72386 17.5 10V17C17.5 17.2761 17.2761 17.5 17 17.5H12H7C6.72386 17.5 6.5 17.2761 6.5 17ZM16.5 16.5V10.5H12.5V16.5H16.5ZM7.5 10.5H11.5V16.5H7.5V10.5Z"
      fill={props.fill}
    />
  </svg>
);
const Memo = memo(SvgIcoEventCalendar);
export default Memo;
