import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoDoneSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.9051 29.6018L33 17.0002C33.3669 16.5874 33.999 16.5503 34.4118 16.9172C34.8246 17.2841 34.8618 17.9162 34.4948 18.3289L22.7474 31.6646C22.3852 32.0721 21.7632 32.1143 21.3492 31.7595L14.3492 25.7595C13.9299 25.4 13.8813 24.7687 14.2408 24.3494C14.6002 23.9301 15.2315 23.8815 15.6508 24.241L21.9051 29.6018Z"
      fill={props.fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40ZM24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42Z"
      fill={props.fill}
    />
  </svg>
);
const Memo = memo(SvgIcoDoneSuccess);
export default Memo;
