import { DefaultSeo as DefaultNextSEO, LogoJsonLd, SocialProfileJsonLd } from 'next-seo';
import { useIntl } from 'react-intl';

import Logo from '/public/android-chrome-512x512.png';
import IntroImage from '/public/images/home_hero-image.png';

const DefaultSEO = () => {
  const intl = useIntl();
  const baseUrl = process.env.NEXT_PUBLIC_FRONTEND_DOMAIN || 'https://belgashare.be/';

  return (
    <>
      <DefaultNextSEO
        titleTemplate="Belga Share - %s"
        defaultTitle="Belga Share"
        description={intl.formatMessage({ id: 'home.introduce.description' })}
        canonical={baseUrl}
        openGraph={{
          url: baseUrl,
          title: 'Belga Share',
          description: intl.formatMessage({ id: 'home.introduce.description' }),
          type: 'website',
          images: [
            {
              url: baseUrl + IntroImage.src,
              width: IntroImage.width,
              height: IntroImage.height,
              alt: intl.formatMessage({ id: 'home.introduce.description' }),
              type: 'image/png',
            },
          ],
          siteName: 'Belga Share',
        }}
        twitter={{
          handle: '@BelgaNewsAgency',
          cardType: 'summary_large_image',
        }}
      />
      <SocialProfileJsonLd
        type="Organization"
        name="Belga Share"
        url={baseUrl}
        sameAs={[
          'https://www.facebook.com/belganewsagency',
          'https://www.youtube.com/user/BelgaNewsAgency',
          'https://www.linkedin.com/company/belga-news-agency/',
          'https://twitter.com/BelgaNewsAgency',
        ]}
      />
      <LogoJsonLd logo={baseUrl + Logo.src} url={baseUrl} />
    </>
  );
};

export default DefaultSEO;
