import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill={props.fill} {...props}>
    <path d="M12 6.261c1.87 0 2.09.007 2.829.041.682.031 1.053.145 1.3.241.326.127.56.279.804.524.245.245.397.478.524.805.096.246.21.617.24 1.3.035.738.042.959.042 2.828 0 1.87-.007 2.09-.041 2.829-.031.682-.145 1.053-.241 1.3a2.2 2.2 0 0 1-.524.804 2.2 2.2 0 0 1-.805.524c-.246.096-.617.21-1.3.241-.737.034-.959.04-2.828.04-1.87 0-2.09-.006-2.829-.04-.682-.031-1.053-.145-1.3-.241a2.2 2.2 0 0 1-.804-.524 2.2 2.2 0 0 1-.524-.805c-.096-.246-.21-.617-.24-1.3-.035-.737-.042-.959-.042-2.828 0-1.87.007-2.09.041-2.829.031-.682.145-1.053.241-1.3.127-.326.279-.56.524-.804.245-.245.478-.397.805-.524.246-.096.617-.21 1.3-.24.738-.035.959-.042 2.828-.042M12 5c-1.901 0-2.14.008-2.886.042-.745.034-1.254.152-1.7.325-.46.18-.85.419-1.24.808-.388.39-.628.78-.807 1.24-.173.445-.29.954-.325 1.699C5.008 9.86 5 10.099 5 12s.008 2.14.042 2.886c.034.745.152 1.254.325 1.7.18.46.419.85.808 1.24.389.388.78.628 1.24.806.445.174.954.292 1.699.326.747.034.985.042 2.886.042s2.14-.008 2.886-.042c.745-.034 1.254-.152 1.7-.325a3.4 3.4 0 0 0 1.24-.808c.388-.389.628-.78.806-1.24.173-.445.292-.954.326-1.699S19 13.901 19 12s-.008-2.14-.042-2.886c-.034-.745-.152-1.254-.325-1.7a3.4 3.4 0 0 0-.808-1.24 3.4 3.4 0 0 0-1.24-.807c-.445-.173-.954-.29-1.699-.325C14.14 5.008 13.901 5 12 5m0 3.405a3.595 3.595 0 1 0 0 7.19 3.595 3.595 0 0 0 0-7.19m0 5.928a2.333 2.333 0 1 1 0-4.666 2.333 2.333 0 0 1 0 4.666m4.577-6.07a.84.84 0 1 1-1.68 0 .84.84 0 0 1 1.68 0"></path>
  </svg>
);
const Memo = memo(SvgIcoInstagram);
export default Memo;
