import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoLearnMore = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.7139 6H17.9996V10.2857"
      stroke="#3349E8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2857 6H8C6.89543 6 6 6.89543 6 8V16C6 17.1046 6.89543 18 8 18H16C17.1046 18 18 17.1046 18 16V13.7143"
      stroke="#3349E8"
      strokeLinecap="round"
    />
    <path d="M18 6L12 12" stroke="#3349E8" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
const Memo = memo(SvgIcoLearnMore);
export default Memo;
