import { Box, LinearProgress, Typography } from '@mui/material';
import { IcoDownload } from 'components/@icons';
import { theme } from 'theme';
import { IStatusDownLoad } from 'types/types';
import { convertFileSize } from 'utils/convertFileSize';
import FlexWrapper from '../FlexWrapper/FlexWrapper';

interface Props {
  downloading: IStatusDownLoad[];
}

const ProgressBarDownload = ({ downloading = [] }: Props) => {
  return downloading.length > 0 ? (
    downloading.map((item, index) => (
      <Box
        key={item.id}
        sx={{
          height: '60px',
          background: '#ffffff',
          borderRadius: '8px',
          position: 'fixed',
          bottom: `${30 + index * 70}px`,
          right: '30px',
          overflow: 'hidden',
          zIndex: 999999,
          display: 'flex',
          alignItems: 'center',
          maxWidth: '300px',
          minWidth: '300px',
        }}
        className="main-shadow-custom"
      >
        <Box
          sx={{
            display: 'flex',
            minWidth: '34px',
            minHeight: '34px',
            borderRadius: '8px',
            paddingX: '2px',
            marginLeft: '12px',
            background: theme.palette.global01[600],
          }}
        >
          {<IcoDownload fill={theme.palette.global01[400]} width={34} height={34} />}
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            padding: '0px 16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <FlexWrapper>
            <Typography
              sx={{
                ...theme.typography.webParagraph02,
                fontSize: '13px',
                fontWeight: 600,
                color: theme.palette.neutral01[200],
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                maxWidth: '250px',
              }}
            >
              {item.fileName && item.fileName}{' '}
              {item.fileSize && `- ${convertFileSize(item.fileSize)}`}
            </Typography>
          </FlexWrapper>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              gap: '16px',
            }}
          >
            <LinearProgress
              variant="determinate"
              value={item.downloadProgress}
              sx={{ color: '#3349E8', borderRadius: '10px', width: '100%' }}
            />
            <Box sx={{ display: 'flex', ...theme.typography.webParagraph01, minWidth: '33px' }}>
              {item.downloadProgress}%
            </Box>
          </Box>
        </Box>
      </Box>
    ))
  ) : (
    <></>
  );
};

export default ProgressBarDownload;
