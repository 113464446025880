import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCSV = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="35"
    height="41"
    viewBox="0 0 35 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.3">
      <path
        opacity="0.3"
        d="M24.2439 8.37251C23.3145 8.37161 22.4234 8.00198 21.7662 7.34479C21.109 6.68759 20.7394 5.7965 20.7385 4.86708V0H5.0747C3.86142 0 2.69782 0.48199 1.8399 1.33991C0.981977 2.19784 0.5 3.3614 0.5 4.57469V32.1521C0.501802 33.3642 0.984574 34.5261 1.8423 35.3825C2.70003 36.239 3.86259 36.72 5.0747 36.72H23.8767C24.4776 36.7203 25.0727 36.6021 25.6279 36.3723C26.1831 36.1425 26.6876 35.8056 27.1126 35.3807C27.5376 34.9559 27.8747 34.4515 28.1047 33.8964C28.3347 33.3413 28.4531 32.7462 28.4531 32.1453V8.3657L24.2439 8.37251Z"
        fill="#00C650"
      />
    </g>
    <path
      d="M28.4529 8.37251H24.2437C23.3143 8.37161 22.4232 8.00198 21.766 7.34479C21.1088 6.68759 20.7392 5.7965 20.7383 4.86708V0L28.4529 8.37251Z"
      fill="#00C650"
    />
    <path
      d="M31.7702 29.0037H10.5134C9.00556 29.0037 7.7832 30.226 7.7832 31.7339V38.0715C7.7832 39.5793 9.00556 40.8017 10.5134 40.8017H31.7702C33.2781 40.8017 34.5004 39.5793 34.5004 38.0715V31.7339C34.5004 30.226 33.2781 29.0037 31.7702 29.0037Z"
      fill="#00C650"
    />
    <path d="M14.1835 36.2305V37.463H12.9102V36.2305H14.1835Z" fill="white" />
    <path
      d="M18.758 32.8337C19.1882 33.1818 19.4805 33.6718 19.5825 34.2158H18.2803C18.1934 33.99 18.0398 33.7961 17.84 33.6599C17.6297 33.522 17.3824 33.4514 17.1311 33.4576C16.9654 33.4526 16.8007 33.4857 16.6498 33.5543C16.4989 33.623 16.3658 33.7254 16.2607 33.8537C16.0271 34.1557 15.9095 34.5315 15.9292 34.9128C15.9093 35.2926 16.027 35.6668 16.2607 35.9668C16.3662 36.0943 16.4995 36.196 16.6503 36.2641C16.8012 36.3321 16.9657 36.3648 17.1311 36.3595C17.3824 36.3656 17.6297 36.295 17.84 36.1572C18.0398 36.0237 18.1944 35.8328 18.2837 35.6098H19.5859C19.4817 36.152 19.1898 36.6402 18.7614 36.9885C18.3017 37.3418 17.7325 37.5224 17.1532 37.4985C16.6969 37.5085 16.2465 37.3951 15.8493 37.1704C15.4772 36.9552 15.1759 36.6362 14.9823 36.2524C14.7728 35.8356 14.6677 35.3741 14.6763 34.9077C14.6675 34.4407 14.7725 33.9786 14.9823 33.5613C15.1759 33.1775 15.4772 32.8584 15.8493 32.6433C16.2465 32.4185 16.6969 32.3052 17.1532 32.3152C17.7323 32.2953 18.3 32.4787 18.758 32.8337Z"
      fill="white"
    />
    <path
      d="M23.7275 36.7711C23.5809 37.0049 23.3711 37.1924 23.1223 37.3117C22.8194 37.4534 22.4875 37.5221 22.1532 37.5123C21.6499 37.5327 21.1539 37.3863 20.7422 37.0958C20.5605 36.9591 20.4117 36.7834 20.307 36.5815C20.2022 36.3797 20.1441 36.1569 20.137 35.9296H21.4477C21.4568 36.1009 21.5303 36.2624 21.6534 36.3818C21.7752 36.4958 21.9372 36.5569 22.1039 36.5518C22.2406 36.5588 22.3747 36.5124 22.4779 36.4226C22.5227 36.3783 22.5579 36.3252 22.5813 36.2667C22.6047 36.2082 22.6158 36.1456 22.6139 36.0826C22.6155 36.0231 22.6052 35.9639 22.5836 35.9084C22.562 35.8529 22.5296 35.8024 22.4881 35.7596C22.3988 35.6689 22.2926 35.5967 22.1753 35.5471C22.0495 35.491 21.8779 35.4247 21.6568 35.3482C21.3871 35.2594 21.1235 35.1527 20.868 35.0286C20.6581 34.92 20.4778 34.7619 20.3427 34.5679C20.1874 34.3325 20.1106 34.054 20.1234 33.7723C20.1165 33.493 20.1967 33.2185 20.3529 32.9869C20.5136 32.7606 20.7344 32.5839 20.9904 32.4769C21.2867 32.357 21.6043 32.2992 21.9237 32.3069C22.4067 32.2813 22.8836 32.4236 23.2735 32.7098C23.44 32.8447 23.5762 33.0131 23.6735 33.204C23.7707 33.395 23.8267 33.6042 23.8379 33.8182H22.5069C22.4905 33.6686 22.426 33.5285 22.3232 33.4187C22.2701 33.3684 22.2073 33.3293 22.1386 33.3039C22.07 33.2784 21.9969 33.2672 21.9237 33.2708C21.7967 33.2643 21.6718 33.3055 21.5735 33.3864C21.5276 33.4296 21.4918 33.4826 21.4689 33.5414C21.446 33.6002 21.4364 33.6634 21.4409 33.7264C21.4395 33.7827 21.4493 33.8388 21.4697 33.8913C21.4901 33.9439 21.5208 33.9918 21.5599 34.0324C21.6442 34.1211 21.7461 34.1912 21.8592 34.2381C21.9782 34.2908 22.1516 34.3588 22.3692 34.4404C22.6439 34.5288 22.9116 34.6379 23.1699 34.7668C23.3816 34.8807 23.5639 35.0425 23.702 35.2394C23.8596 35.4777 23.9376 35.7598 23.9247 36.0452C23.9259 36.3006 23.8577 36.5515 23.7275 36.7711Z"
      fill="white"
    />
    <path
      d="M25.5646 32.373L26.8107 36.2032L28.0568 32.373H29.3743L27.6029 37.4629H26.0117L24.2471 32.373H25.5646Z"
      fill="white"
    />
    <path
      d="M20.7114 25.6361H8.24186C7.16236 25.6361 6.28516 24.5617 6.28516 23.2391V14.1968C6.28516 12.8759 7.16236 11.8015 8.24186 11.8015H20.7114C21.7909 11.8015 22.6681 12.8759 22.6681 14.1968V23.2391C22.6681 24.5617 21.7909 25.6361 20.7114 25.6361ZM8.24186 13.0255C7.71486 13.0255 7.28476 13.5508 7.28476 14.1968V23.2391C7.28476 23.8851 7.71486 24.4105 8.24186 24.4105H20.7114C21.2384 24.4105 21.6668 23.8851 21.6668 23.2391V14.1968C21.6668 13.5508 21.2384 13.0255 20.7114 13.0255H8.24186Z"
      fill="#00C650"
    />
    <path d="M22.1685 20.1909H6.78516V21.4166H22.1685V20.1909Z" fill="#00C650" />
    <path d="M22.1685 15.9885H6.78516V17.2142H22.1685V15.9885Z" fill="#00C650" />
    <path d="M18.2296 12.4136H17.0039V25.0225H18.2296V12.4136Z" fill="#00C650" />
    <path d="M11.9493 12.4136H10.7236V25.0225H11.9493V12.4136Z" fill="#00C650" />
  </svg>
);
const Memo = memo(SvgIcoCSV);
export default Memo;
