import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCheckboxDate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 6C12.7761 6 13 6.22386 13 6.5C13 6.77614 12.7761 7 12.5 7H7V17H17V12.5C17 12.2239 17.2239 12 17.5 12C17.7761 12 18 12.2239 18 12.5V17C18 17.5523 17.5523 18 17 18H7C6.44772 18 6 17.5523 6 17V7C6 6.44772 6.44772 6 7 6H12.5ZM16.9876 7.37157C17.1724 7.16636 17.4885 7.14979 17.6937 7.33457C17.899 7.51934 17.9155 7.83549 17.7308 8.0407L12.3777 13.9859C12.1929 14.1911 11.8768 14.2076 11.6716 14.0229C11.6483 14.0019 11.6275 13.9793 11.6091 13.9553L9.38302 12.0874C9.17148 11.9099 9.14389 11.5946 9.32139 11.383C9.49889 11.1715 9.81427 11.1439 10.0258 11.3214L11.9656 12.9491L16.9876 7.37157Z"
      fill={props.fill}
    />
  </svg>
);
const Memo = memo(SvgIcoCheckboxDate);
export default Memo;
