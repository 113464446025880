import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0038 12.2308C12.449 12.2308 12.829 12.0723 13.1437 11.7552C13.4585 11.4382 13.6158 11.0571 13.6158 10.612C13.6158 10.1668 13.4573 9.78686 13.1403 9.47213C12.8233 9.15738 12.4422 9 11.997 9C11.5519 9 11.1719 9.15851 10.8572 9.47552C10.5424 9.79252 10.3851 10.1736 10.3851 10.6188C10.3851 11.064 10.5436 11.4439 10.8606 11.7587C11.1776 12.0734 11.5587 12.2308 12.0038 12.2308ZM12.0004 20.1769C13.8799 18.541 15.3658 16.8772 16.4581 15.1856C17.5504 13.4939 18.0966 12.0308 18.0966 10.7962C18.0966 8.96667 17.5174 7.45673 16.3591 6.26635C15.2008 5.07597 13.7479 4.48078 12.0004 4.48078C10.253 4.48078 8.80013 5.07597 7.6418 6.26635C6.48346 7.45673 5.9043 8.96667 5.9043 10.7962C5.9043 12.0308 6.45045 13.4939 7.54275 15.1856C8.63506 16.8772 10.121 18.541 12.0004 20.1769ZM12.0004 21.5192C9.65045 19.441 7.88025 17.5029 6.68987 15.7048C5.49949 13.9067 4.9043 12.2705 4.9043 10.7962C4.9043 8.68077 5.59308 6.93589 6.97065 5.56153C8.3482 4.18717 10.0248 3.5 12.0004 3.5C13.9761 3.5 15.6527 4.18717 17.0302 5.56153C18.4078 6.93589 19.0966 8.68077 19.0966 10.7962C19.0966 12.2705 18.5014 13.9067 17.311 15.7048C16.1206 17.5029 14.3504 19.441 12.0004 21.5192Z"
      fill={props.fill}
    />
  </svg>
);
const Memo = memo(SvgIcoLocation);
export default Memo;
