import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoFolder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40 20.6667C40 19.7237 39.6254 18.8193 38.9586 18.1525C38.2918 17.4857 37.3874 17.1111 36.4444 17.1111H24L20.4444 10H11.5556C10.6126 10 9.70819 10.3746 9.0414 11.0414C8.3746 11.7082 8 12.6126 8 13.5556V34.8889C8 35.8319 8.3746 36.7363 9.0414 37.403C9.70819 38.0698 10.6126 38.4444 11.5556 38.4444H36.4444C37.3874 38.4444 38.2918 38.0698 38.9586 37.403C39.6254 36.7363 40 35.8319 40 34.8889V20.6667Z"
      fill={props.fill}
    />
  </svg>
);
const Memo = memo(SvgIcoFolder);
export default Memo;
