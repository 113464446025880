import { createSlice } from '@reduxjs/toolkit';

type initialStateType = {
  tabActive: string;
};

const initialState: initialStateType = {
  tabActive: 'latest-press-releases',
};

const cacheSlice = createSlice({
  name: 'cache-slice',
  initialState: initialState,
  reducers: {
    setTabActive: (state, action) => {
      state.tabActive = action.payload;
    },
  },
});

const { reducer, actions } = cacheSlice;
export const { setTabActive } = actions;
export default reducer;
