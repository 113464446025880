import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCloseSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7712 4.70016C11.9014 4.56999 11.9014 4.35893 11.7712 4.22876C11.6411 4.09858 11.43 4.09858 11.2998 4.22876L8 7.52859L4.70017 4.22876C4.56999 4.09858 4.35894 4.09858 4.22876 4.22876C4.09859 4.35893 4.09859 4.56999 4.22876 4.70016L7.5286 7.99999L4.22876 11.2998C4.09859 11.43 4.09859 11.6411 4.22876 11.7712C4.35894 11.9014 4.56999 11.9014 4.70017 11.7712L8 8.4714L11.2998 11.7712C11.43 11.9014 11.6411 11.9014 11.7712 11.7712C11.9014 11.6411 11.9014 11.43 11.7712 11.2998L8.4714 7.99999L11.7712 4.70016Z"
      fill={props.fill}
    />
  </svg>
);
const Memo = memo(SvgIcoCloseSmall);
export default Memo;
