import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoTagName = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0903 15.5315C10.0188 15.7982 10.1771 16.0724 10.4439 16.1438L10.9706 16.285C11.2373 16.3564 11.5115 16.1981 11.5829 15.9314L12.3795 12.9587H14.4831C14.7592 12.9587 14.9831 12.7349 14.9831 12.4587V11.9134C14.9831 11.6373 14.7592 11.4134 14.4831 11.4134H12.7935L13.4146 9.0955H15.3327C15.6089 9.0955 15.8327 8.87164 15.8327 8.5955V8.0502C15.8327 7.77406 15.6089 7.5502 15.3327 7.5502H13.8287L14.5237 4.95621C14.5952 4.68948 14.4369 4.41531 14.1702 4.34384L13.6435 4.20271C13.3767 4.13124 13.1026 4.28953 13.0311 4.55626L12.2289 7.5502H9.19278L9.88783 4.95621C9.9593 4.68948 9.80101 4.41531 9.53428 4.34384L9.00757 4.20271C8.74083 4.13124 8.46667 4.28953 8.39519 4.55626L7.59297 7.5502H5.51567C5.23952 7.5502 5.01567 7.77406 5.01567 8.05021V8.5955C5.01567 8.87164 5.23952 9.0955 5.51567 9.0955H7.17891L6.55782 11.4134H4.66602C4.38987 11.4134 4.16602 11.6373 4.16602 11.9134V12.4587C4.16602 12.7349 4.38987 12.9587 4.66602 12.9587H6.14376L5.4544 15.5315C5.38293 15.7982 5.54122 16.0724 5.80796 16.1438L6.33467 16.285C6.6014 16.3564 6.87557 16.1981 6.94704 15.9314L7.74357 12.9587H10.7797L10.0903 15.5315ZM11.1937 11.4134L11.8148 9.0955H8.77872L8.15763 11.4134H11.1937Z"
      fill={props.fill}
    />
  </svg>
);
const Memo = memo(SvgIcoTagName);
export default Memo;
