import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoFacebookSecondary = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2273 5.37549H5.77267C5.34589 5.37549 5 5.72138 5 6.14815V18.6028C5 19.0295 5.34589 19.3755 5.77267 19.3755H12.4779V13.9539H10.6534V11.841H12.4779V10.2829C12.4779 8.47464 13.5823 7.49001 15.1954 7.49001C15.9681 7.49001 16.6322 7.5475 16.8257 7.5732V9.46295L15.7069 9.46347C14.8296 9.46347 14.6597 9.88033 14.6597 10.4921V11.841H16.752L16.4796 13.9539H14.6597V19.3755H18.2273C18.6541 19.3755 19 19.0295 19 18.6028V6.14815C19 5.72138 18.6541 5.37549 18.2273 5.37549Z"
      fill={props.fill}
    />
  </svg>
);
const Memo = memo(SvgIcoFacebookSecondary);
export default Memo;
