import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.3536 15.6464C14.5488 15.8417 14.5488 16.1583 14.3536 16.3536C14.1583 16.5488 13.8417 16.5488 13.6464 16.3536L9.64645 12.3536C9.45118 12.1583 9.45118 11.8417 9.64645 11.6464L13.6464 7.64645C13.8417 7.45118 14.1583 7.45118 14.3536 7.64645C14.5488 7.84171 14.5488 8.15829 14.3536 8.35355L10.7071 12L14.3536 15.6464Z"
      fill={props.fill}
    />
    {/* <mask id="mask0_35348_2121" maskUnits="userSpaceOnUse" x="9" y="7" width="6" height="10">
      <path
        d="M14.3536 15.6464C14.5488 15.8417 14.5488 16.1583 14.3536 16.3536C14.1583 16.5488 13.8417 16.5488 13.6464 16.3536L9.64645 12.3536C9.45118 12.1583 9.45118 11.8417 9.64645 11.6464L13.6464 7.64645C13.8417 7.45118 14.1583 7.45118 14.3536 7.64645C14.5488 7.84171 14.5488 8.15829 14.3536 8.35355L10.7071 12L14.3536 15.6464Z"
        fill={props.fill}
      />
    </mask>
    <g mask="url(#mask0_35348_2121)">
      <rect width="24" height="24" fill={props.fill} />
    </g> */}
  </svg>
);
const Memo = memo(SvgIcoArrowLeft);
export default Memo;
