import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoSocial = (props: SVGProps<SVGSVGElement>) => (
  <svg id="Layer_1" viewBox="0 0 24 24" width="1em" height="1em" role="img" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12.3755C4 16.7938 7.58172 20.3755 12 20.3755C16.4183 20.3755 20 16.7938 20 12.3755C20 7.95721 16.4183 4.37549 12 4.37549C7.58172 4.37549 4 7.95721 4 12.3755ZM17.5626 16.6255C16.7473 17.691 15.6328 18.5154 14.3446 18.9732C14.9813 18.3419 15.476 17.5583 15.8299 16.6255H17.5626ZM18.2014 15.6255C18.6383 14.7935 18.9127 13.8627 18.9824 12.8755H16.493C16.4643 13.885 16.3473 14.8022 16.1413 15.6255H18.2014ZM15.1076 15.6255C15.333 14.8259 15.4617 13.91 15.4927 12.8755H12.5V15.6255H15.1076ZM12.5 16.6255H14.7487C14.298 17.6334 13.6456 18.3998 12.7946 18.9316C12.7007 18.9903 12.6018 19.0377 12.5 19.0737V16.6255ZM11.5 15.6255V12.8755H8.50734C8.53828 13.91 8.6669 14.8259 8.89219 15.6255H11.5ZM9.25095 16.6255H11.5V19.0739C11.398 19.0379 11.299 18.9905 11.2049 18.9317C10.354 18.4001 9.70161 17.6337 9.25095 16.6255ZM7.85853 15.6255C7.65263 14.8023 7.53569 13.8851 7.507 12.8755H5.01758C5.08726 13.8627 5.36166 14.7935 5.7986 15.6255H7.85853ZM6.43739 16.6255H8.16985C8.52355 17.5581 9.01792 18.3416 9.65435 18.9728C8.36659 18.5149 7.25247 17.6907 6.43739 16.6255ZM18.9824 11.8755C18.9196 10.986 18.6907 10.1424 18.3264 9.37549H16.2005C16.3698 10.1332 16.4672 10.967 16.493 11.8755H18.9824ZM14.3446 5.77779C15.7319 6.2708 16.9177 7.18894 17.7453 8.37549H15.9201C15.562 7.33198 15.0374 6.46473 14.3446 5.77779ZM12.7946 5.81941C13.7146 6.39434 14.4025 7.24344 14.8544 8.37549H12.5V5.67728C12.6018 5.71331 12.7007 5.76069 12.7946 5.81941ZM12.5 11.8755V9.37549H15.174C15.3584 10.1144 15.4649 10.9472 15.4927 11.8755H12.5ZM11.5 11.8755V9.37549H8.82584C8.64152 10.1144 8.53511 10.9472 8.50734 11.8755H11.5ZM11.5 5.67705V8.37549H9.14531C9.59713 7.24312 10.285 6.39399 11.2049 5.81927C11.299 5.76049 11.398 5.71308 11.5 5.67705ZM9.65434 5.77817C8.96184 6.46496 8.43753 7.33208 8.07963 8.37549H6.25469C7.08212 7.18925 8.26754 6.27127 9.65434 5.77817ZM5.01758 11.8755C5.08036 10.986 5.30933 10.1424 5.67363 9.37549H7.79938C7.63011 10.1332 7.53282 10.9669 7.507 11.8755H5.01758Z"
      fill={props.fill}
    />
  </svg>
);
const Memo = memo(SvgIcoSocial);
export default Memo;
