import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IStatusDownLoad } from 'types/types';

type initialStateType = {
  downloading: IStatusDownLoad[];
};

const initialState: initialStateType = {
  downloading: [],
};

const progressSlice = createSlice({
  name: 'progress-slice',
  initialState: initialState,
  reducers: {
    setDownLoading: (state, action) => {
      state.downloading = action.payload;
    },
    updateDownloadProgress: (state, action: PayloadAction<IStatusDownLoad>) => {
      const index = state.downloading.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        state.downloading[index] = action.payload;
      } else {
        state.downloading.push(action.payload);
      }
    },
    removeDownload: (state, action: PayloadAction<string>) => {
      state.downloading = state.downloading.filter((item) => item.id !== action.payload);
    },
  },
});

const { reducer, actions } = progressSlice;
export const { setDownLoading, updateDownloadProgress, removeDownload } = actions;
export default reducer;
