import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoWeb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12ZM17.5626 16.25C16.7473 17.3155 15.6328 18.1399 14.3446 18.5977C14.9813 17.9664 15.476 17.1828 15.8299 16.25H17.5626ZM18.2014 15.25C18.6383 14.418 18.9127 13.4873 18.9824 12.5H16.493C16.4643 13.5096 16.3473 14.4268 16.1413 15.25H18.2014ZM15.1076 15.25C15.333 14.4504 15.4617 13.5345 15.4927 12.5H12.5V15.25H15.1076ZM12.5 16.25H14.7487C14.298 17.2579 13.6456 18.0243 12.7946 18.5561C12.7007 18.6148 12.6018 18.6622 12.5 18.6982V16.25ZM11.5 15.25V12.5H8.50734C8.53828 13.5345 8.6669 14.4504 8.89219 15.25H11.5ZM9.25095 16.25H11.5V18.6984C11.398 18.6624 11.299 18.615 11.2049 18.5562C10.354 18.0246 9.70161 17.2582 9.25095 16.25ZM7.85853 15.25C7.65263 14.4268 7.53569 13.5096 7.507 12.5H5.01758C5.08726 13.4873 5.36166 14.418 5.7986 15.25H7.85853ZM6.43739 16.25H8.16985C8.52355 17.1827 9.01792 17.9661 9.65435 18.5973C8.36659 18.1394 7.25247 17.3152 6.43739 16.25ZM18.9824 11.5C18.9196 10.6105 18.6907 9.76687 18.3264 9H16.2005C16.3698 9.75776 16.4672 10.5915 16.493 11.5H18.9824ZM14.3446 5.4023C15.7319 5.89531 16.9177 6.81346 17.7453 8H15.9201C15.562 6.95649 15.0374 6.08924 14.3446 5.4023ZM12.7946 5.44392C13.7146 6.01886 14.4025 6.86795 14.8544 8H12.5V5.30179C12.6018 5.33782 12.7007 5.3852 12.7946 5.44392ZM12.5 11.5V9H15.174C15.3584 9.73894 15.4649 10.5717 15.4927 11.5H12.5ZM11.5 11.5V9H8.82584C8.64152 9.73891 8.53511 10.5717 8.50734 11.5H11.5ZM11.5 5.30156V8H9.14531C9.59713 6.86764 10.285 6.0185 11.2049 5.44378C11.299 5.385 11.398 5.3376 11.5 5.30156ZM9.65434 5.40268C8.96184 6.08947 8.43753 6.9566 8.07963 8H6.25469C7.08212 6.81376 8.26754 5.89579 9.65434 5.40268ZM5.01758 11.5C5.08036 10.6105 5.30933 9.76687 5.67363 9H7.79938C7.63011 9.75774 7.53282 10.5915 7.507 11.5H5.01758Z"
      fill={props.fill}
    />
  </svg>
);
const Memo = memo(SvgIcoWeb);
export default Memo;
