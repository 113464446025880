import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCheckbox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="-6" y="-6" width="24" height="24" fill="white" />
  </svg>
);
const Memo = memo(SvgIcoCheckbox);
export default Memo;
