import { Box } from '@mui/material';
import { IcoBackToTop } from 'components/@icons';
import React, { useEffect, useState } from 'react';
import { theme } from 'theme';

const BackToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <Box
      onClick={scrollToTop}
      sx={{
        display: isVisible ? 'flex' : 'none',
        background: theme.palette.global01[400],
        zIndex: 1002,
        ':hover': {
          background: theme.palette.global01[300],
        },
      }}
      className="back-to-top__button"
    >
      <IcoBackToTop
        fill={theme.palette.neutral01[700]}
        width={24}
        height={24}
        className="back-to-top__icon"
      />
    </Box>
  );
};

export default BackToTopButton;
